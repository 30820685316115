import { lazy, useEffect, useState } from 'react';
import {
  BrowserRouter, Routes, Route,
} from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

// loading CSS style
import 'react-toastify/dist/ReactToastify.css';
import '@assets/scss/main.scss';

// loading bootstrap js files.
import 'bootstrap/dist/js/bootstrap';

import HomePage from '@pages/HomePage';
import LoginPage from '@pages/LoginPage';
import NotFoundPage from '@pages/NotFoundPage';
import RegisterPage from '@pages/RegisterPage';
import PricingPage from '@pages/PricingPage';
import ContactPage from '@pages/ContactPage';
import AdminDashboardPage from '@pages/admin/AdminDashboardPage';
import NoAuthRequired from '@pages/middlewares/NoAuthRequired';
import CustomersPage from '@pages/admin/CustomersPage';
import AddCustomerPage from '@pages/admin/AddCustomerPage';
import EditCustomerPage from '@pages/admin/EditCustomerPage';
import EditCustomerQuotaPage from '@pages/admin/EditCustomerQuotaPage';
import PackagesPage from '@pages/admin/PackagesPage';
import { PagesPage } from '@pages/admin/PagesPage';
import AddPagePage from '@pages/admin/AddPagePage';
import EditPagePage from '@pages/admin/EditPagePage';
import { SettingsPage } from '@pages/admin/SettingsPage';
import CheckoutPage from '@pages/CheckoutPage';
import { OrdersListPage } from '@pages/admin/OrdersListPage';
import UserRequired from '@pages/middlewares/UserRequired';
import ThankYouPage from '@pages/ThankYouPage';
import AccountDetailsPage from '@pages/account/settings/AccountDetailsPage';
import AccountSettingsLayout from '@pages/layouts/AccountSettingsLayout';
import OrdersPage from '@pages/account/settings/OrdersPage';
import ChangePasswordPage from '@pages/account/settings/ChangePasswordPage';
import AccountPage from '@pages/account/AccountPage';
import ResetPasswordPage from '@pages/ResetPasswordPage';
import PagePage from '@pages/PagePage';
import LogoutPage from '@pages/LogoutPage';
import { useAuth, useUser } from '@hooks/auth';
import { gtag } from '@utils/index';
import { useLocalStore } from '@hooks/index';
import RegisteredPage from '@pages/RegisteredPage';

// Lazy loading
const AdminDashboardLayout = lazy(() => import('@pages/layouts/AdminDashboardLayout'));
const AccountLayout = lazy(() => import('@pages/layouts/AccountLayout'));
const PlaygroundPage = lazy(() => import('@pages/PlaygroundPage'));

function App() {
  const { isAuthenticated } = useUser();
  const { GoogleAuthenticate } = useAuth();
  const [, setGooglePlayApp] = useLocalStore('googlePlayApp', false);
  const [googleAuthInitialized, setGoogleAuthInitialized] = useState(false);

  const googleSignIn = (result) => {
    if (result.credential) {
      GoogleAuthenticate(result.credential).then((data) => {
        if (!data.errors) {
          toast.success(data.message);
          gtag('event', 'sign_up', {
            method: 'Google',
          });
          return;
        } toast.error(data.message);
      });
    }
  };

  useEffect(() => {
    if (window.google) {
      if (!isAuthenticated) {
        /* global google */
        google.accounts.id.initialize({
          client_id: '563247972376-1cegckfsm2ic25llhijg5uck2a8328in.apps.googleusercontent.com',
          callback: googleSignIn,
          cancel_on_tap_outside: false,
        });
        google.accounts.id.prompt();
        setGoogleAuthInitialized(true);
      } else {
        google.accounts.id.cancel();
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const googlePlayBilling = async () => {
      if ('getDigitalGoodsService' in window) {
        try {
          await window.getDigitalGoodsService('https://play.google.com/billing');
          setGooglePlayApp(true);
        } catch (error) {
          setGooglePlayApp(false);
        }
      } else {
        setGooglePlayApp(false);
      }
    };

    googlePlayBilling();
  }, []);

  return (
  // eslint-disable-next-line react/jsx-filename-extension
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage googleAuthInitialized={googleAuthInitialized} />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/p/:slug" element={<PagePage />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/pricing" element={<PricingPage googleAuthInitialized={googleAuthInitialized} />} />

        <Route element={<NoAuthRequired />}>
          <Route path="/login" element={<LoginPage googleAuthInitialized={googleAuthInitialized} />} />
          <Route path="/register" element={<RegisterPage googleAuthInitialized={googleAuthInitialized} />} />
          <Route path="/registered" element={<RegisteredPage />} />
          <Route path="/reset/:token" element={<ResetPasswordPage />} />
        </Route>

        <Route element={<UserRequired />}>
          <Route path="/playground/:uuid?" element={<PlaygroundPage />} />
          <Route path="/checkout/:id" element={<CheckoutPage />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
        </Route>

        <Route path="/account" element={<AccountLayout />}>
          <Route path="" element={<AccountPage />} />

          <Route path="settings" element={<AccountSettingsLayout />}>
            <Route path="invoices" element={<OrdersPage />} />
          </Route>

          <Route path="settings" element={<AccountSettingsLayout />}>
            <Route path="" element={<AccountDetailsPage />} />
            <Route path="change-password" element={<ChangePasswordPage />} />
          </Route>
        </Route>

        <Route path="/admin" element={<AdminDashboardLayout />}>
          <Route path="" element={<AdminDashboardPage />} />
          <Route path="customers" element={<CustomersPage />} />
          <Route path="customers/add" element={<AddCustomerPage />} />
          <Route path="customers/edit/:id" element={<EditCustomerPage />} />
          <Route path="customers/edit/quota/:id" element={<EditCustomerQuotaPage />} />
          <Route path="packages" element={<PackagesPage />} />
          <Route path="orders-list" element={<OrdersListPage />} />
          <Route path="pages" element={<PagesPage />} />
          <Route path="pages/add" element={<AddPagePage />} />
          <Route path="pages/edit/:id" element={<EditPagePage />} />
          <Route path="settings" element={<SettingsPage />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <ToastContainer position="top-center" hideProgressBar draggable={false} theme="colored" />
    </BrowserRouter>
  );
}

export default App;
