// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react';
import { useAuth } from '@hooks/auth';

export function FBLoginButton() {
  const { FacebookAuthenticate } = useAuth();

  const facebookSignIn = (token) => {
    if (!token) return;

    FacebookAuthenticate(token)
      .then((data) => {
        if (!data.errors) {
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        toast.error('Wystąpił nieoczekiwany błąd. Spróbuj ponownie.');
      });
  };

  useEffect(() => {
    if (window.FB) {
      window.FB.XFBML.parse();

      window.checkLoginState = () => {
        window.FB.getLoginStatus((response) => {
          if (response.status === 'connected') {
            facebookSignIn(response.authResponse.accessToken);
          } else {
            toast.error('Logowanie nie powiodło się. Spróbuj ponownie.');
          }
        });
      };
    }
  }, []);

  return (
    <div
      className="fb-login-button"
      data-width="320"
      data-size="large"
      data-button-type="continue_with"
      data-layout="rounded"
      data-auto-logout-link="false"
      data-use-continue-as="true"
      data-scope="public_profile,email"
      data-onlogin="checkLoginState()"
    />
  );
}
