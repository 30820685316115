import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { customerDetails, updateCustomerQuota } from '../../api/admin';
import GoBackButton from '../../components/GoBackButton';
import SectionLoading from '../../components/SectionLoading';
import SuperButton from '../../components/SuperButton';
import { toastFormikErrors } from '../../utils';

export default function EditCustomerPage() {
  const { id } = useParams();
  const {
    isLoading, isError, error, data: customer,
  } = useQuery(`admin.customer.${id}`, () => customerDetails(id), { staleTime: Infinity });

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: {
      pdfs: 0,
      questions: 0,
    },
    validationSchema: Yup.object({
      pdfs: Yup.number().required('Photos is required.'),
      questions: Yup.number().required('Questions is required.'),
    }),
    onSubmit: (values) => {
      updateCustomerQuota(customer?.id, values).then((data) => {
        if (data.errors === false) {
          formik.resetForm();
          toast.success(data.message);
          queryClient.invalidateQueries('admin.customers');
          queryClient.invalidateQueries(`admin.customer.${id}`);
          return navigate(-1);
        }
        return toast.error(data.message);
      }).catch((error) => {
        toast.error(error.response.data.message);
      }).finally(() => {
        formik.setSubmitting(false);
      });
    },
  });

  useEffect(() => {
    if (!isLoading && customer) {
      formik.setValues({
        pdfs: customer.quota.pdfs,
        questions: customer.quota.questions,
      });
    }
  }, [customer]);

  if (isLoading) {
    return <SectionLoading />;
  }

  if (isError) {
    toast.error(error.message);
  }

  return (
    <>
      <h1 className="mb-3">Edit Customer Quota</h1>
      <div className="row">
        <div className="col-7">
          <GoBackButton />
          <section className="bg-light rounded p-4">
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-4">
                <label htmlFor="email">Tasks:</label>
                <input type="text" className="form-control" id="pdfs" {...formik.getFieldProps('pdfs')} />
              </div>
              <div className="mb-4">
                <label htmlFor="email">Questions:</label>
                <input type="text" className="form-control" id="questions" {...formik.getFieldProps('questions')} />
              </div>

              <SuperButton
                isLoading={formik.isSubmitting}
                type="submit"
                className="btn btn-primary"
                onClick={() => toastFormikErrors(formik.errors)}
              >
                Update
              </SuperButton>
            </form>
          </section>
        </div>
      </div>
    </>
  );
}
