import Heading from '../components/Heading';
import PricingCards from '../components/PricingCards';
import BasePage from './layouts/BasePage';

export default function PricingPage({ googleAuthInitialized }) {
  return (
    <BasePage>
      <Heading title="Pakiety" subTitle="Strona główna > Pakiety" />
      <section className="container">
        <PricingCards googleAuthInitialized={googleAuthInitialized} />
      </section>
    </BasePage>
  );
}
