import {
  faPen, faSignIn, faSortNumericUp, faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useLocalStore, useNaiveLocalStorage } from '@hooks/index';
import { deleteCustomer, loginCustomer } from '../../api/admin';
import SectionLoading from '../../components/SectionLoading';
import { useCustomers } from '../../hooks/admin';
import { datetimeFormat } from '../../utils';
import AdvancedDataTable from '../../components/AdvancedDataTable';

export default function CustomersPage() {
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useLocalStore('user', {});
  // eslint-disable-next-line no-unused-vars
  const [getToken, setToken] = useNaiveLocalStorage('tk', '');
  const queryClient = useQueryClient();
  const {
    isLoading, isError, error, customers,
  } = useCustomers();
  const handleDelete = (customerId) => {
    Swal.fire({
      title: 'Are you sure you want to delete this customer?',
      icon: 'warning',
      confirmButtonText: 'Yes, delete!',
      cancelButtonText: 'No, cancel!',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCustomer(customerId).then((data) => {
          if (!data?.errors) {
            queryClient.invalidateQueries('admin.customers');
            toast.success(data?.message);
          } else {
            toast.error(data?.message);
          }
        });
      }
    });
  };

  const handleLogin = (customerId) => {
    loginCustomer(customerId).then((data) => {
      if (!data?.errors) {
        setUser(data.user);
        setToken(data.token);
        toast.success(data?.message);
      } else {
        toast.error(data?.message);
      }
    });
  };

  const columns = useMemo(() => [
    {
      name: 'ID',
      sortable: true,
      selector: (customer) => customer.id,
    },
    {
      name: 'Username',
      sortable: true,
      selector: (customer) => customer.username,
    },
    {
      name: 'Email',
      sortable: true,
      selector: (customer) => customer.email,
    },
    {
      name: 'Status',
      selector: (customer) => (customer.is_active ? (
        <span className="badge text-bg-success">Active</span>
      ) : (
        <span className="badge text-bg-warning">Inactive</span>
      )),
    },
    {
      name: 'Joined',
      sortable: true,
      selector: (customer) => datetimeFormat(customer.created_at),
    },
    {
      name: 'Actions',
      selector: (customer) => (
        <>
          <button
            type="button"
            onClick={() => handleLogin(customer.id)}
            className="btn btn-success btn-sm mx-1 mb-1"
          >
            <FontAwesomeIcon icon={faSignIn} />
          </button>
          <Link to={`edit/${customer.id}`} className="btn btn-primary btn-sm  mx-1 mb-1">
            <FontAwesomeIcon
              icon={faPen}
            />
          </Link>
          <Link to={`edit/quota/${customer.id}`} className="btn btn-info btn-sm  mx-1 mb-1">
            <FontAwesomeIcon
              icon={faSortNumericUp}
            />
          </Link>

          <button
            type="button"
            onClick={() => handleDelete(customer.id)}
            className="btn btn-danger btn-sm mx-1 mb-1"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      ),
    },
  ], []);

  if (isLoading) {
    return <SectionLoading />;
  }

  if (isError) {
    return toast.error(error.message);
  }

  return (
    <>
      <h1 className="mb-3">Customers</h1>

      <div className="row">
        <div className="col-12">
          <section className="bg-light rounded p-4">

            <AdvancedDataTable
              columns={columns}
              data={customers}
              pagination
              subHeader
              searchFunction={(item, searchQuery) => item.email.toLowerCase()?.includes(searchQuery) || item.username.toLowerCase()?.includes(searchQuery)}
            />
          </section>
        </div>
      </div>
    </>
  );
}
