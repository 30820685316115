import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import TablerIcon from '@components/TablerIcon';
import {
  IconPhotoUp, IconRotate2, IconRotateClockwise2, IconX,
} from '@tabler/icons-react';
import { toast } from 'react-toastify';

function formatBytes(bytes, decimals = 1) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export default function Dropzone({
  children,
  onUpload,
  onError,
  uploadMessage = 'Ładowanie...',
  completedUploadMessage = 'Załadowano.',
  showProgressBar = true,
  showProgress = true,
  name = null,
  extraOnUploadProps = {},
  dropzoneOptions = {},
}) {
  const [isUploading, setIsUploading] = useState(false);
  const [isCropperLoaded, setIsCropperLoaded] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [progress, setProgressValue] = useState(0); // in %
  const [isSuccessUpload, setIsSuccessUpload] = useState(false);
  const [image, setImage] = useState(null);
  const cropperRef = React.createRef();

  const { isAuthenticated, open } = extraOnUploadProps;

  const maxSize = 1024 * 1024;
  const maxWidth = 800;
  const maxHeight = 600;
  const acceptedExtensions = ['jpg', 'jpeg', 'png'];

  const {
    getRootProps, getInputProps, acceptedFiles, fileRejections, isFocused, isDragAccept, isDragActive,
  } = useDropzone({
    accept: { 'image/*': ['.png', '.jpg', '.jpeg'] },
    maxSize, // in bytes
    multiple: false,
    ...dropzoneOptions,
    onDrop: (acceptedFiles) => {
      if (isAuthenticated === false && typeof open === 'function') {
        return open();
      }
      const fileExtension = acceptedFiles[0].name.split('.').pop();
      if (!acceptedExtensions.includes(fileExtension)) {
        toast.error('Niepoprawny format pliku.');
        return;
      }
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    },
  });

  const setProgress = useCallback((value) => {
    // eslint-disable-next-line no-nested-ternary
    const newValue = value > 100 ? 100 : (value < 0 ? 0 : value);
    setProgressValue(newValue);
    if (newValue >= 100) setIsCompleted(true);
  }, [isUploading]);

  const resetDropzone = useCallback(() => {
    setIsCompleted(false);
    setIsUploading(false);
    setProgressValue(0);
    setIsSuccessUpload(false);
    setImage(null);
  }, []);

  const rotateImage = (degree) => {
    if (cropperRef.current) {
      cropperRef.current.cropper.rotate(degree);
    }
  };

  const onCrop = () => {
    const { cropper } = cropperRef.current;
    const originalMime = acceptedFiles[0].type;
    const croppedCanvas = cropper.getCroppedCanvas();

    const scaleWidth = maxWidth / croppedCanvas.width;
    const newWidth = croppedCanvas.width * scaleWidth;
    const newHeight = croppedCanvas.height * scaleWidth;

    const resizedCanvas = document.createElement('canvas');
    resizedCanvas.width = newWidth > maxWidth ? maxWidth : newWidth;

    if (newHeight > maxHeight) {
      const scaleHeight = maxHeight / croppedCanvas.height;
      resizedCanvas.width = croppedCanvas.width * scaleHeight;
      resizedCanvas.height = maxHeight;
    } else {
      resizedCanvas.height = newHeight;
    }

    if (resizedCanvas.width === 0 || resizedCanvas.height === 0) {
      toast.error('Niepoprawne przycięcie pliku.');
      return;
    }

    const ctx = resizedCanvas.getContext('2d');
    ctx.drawImage(croppedCanvas, 0, 0, resizedCanvas.width, resizedCanvas.height);

    resizedCanvas.toBlob((blob) => {
      const fileName = acceptedFiles[0].name;
      const newFile = new File([blob], fileName, {
        type: originalMime,
        lastModified: Date.now(),
      });
      setIsUploading(true);
      onUpload({
        ...extraOnUploadProps, files: [newFile], setProgress, setIsSuccessUpload, resetDropzone, name,
      });
    }, originalMime, 1);

    setIsCropperLoaded(false);
  };

  useEffect(useCallback(() => {
    if (typeof onError === 'function' && fileRejections.length) {
      setIsUploading(false);
      setIsCropperLoaded(false);
      onError(fileRejections);
    }
  }, [fileRejections.length]), [fileRejections.length]);

  const onCropperLoad = () => {
    setIsCropperLoaded(true);
  };

  const handleAbort = useCallback((e) => {
    e.preventDefault();
    setIsCropperLoaded(false);
    resetDropzone();
  });

  useEffect(() => {
    if (isCompleted) {
      setTimeout(() => {
        resetDropzone();
        setIsCropperLoaded(false);
      }, 5000);
    }
  }, [isCompleted]);

  return (
    <>
      {image && !isUploading ? (
        <>
          <Cropper
            src={image}
            style={{
              height: '100vh', width: '100%', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 9997,
            }}
            guides={false}
            ref={cropperRef}
            onLoad={onCropperLoad}
            autoCropArea={1}
            viewMode={1}
          />
          {isCropperLoaded && (
            <>
              <div style={{
                position: 'fixed',
                left: '50%',
                top: '15px',
                transform: 'translateX(-50%)',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                zIndex: 9998,
              }}
              >
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={resetDropzone}
                >
                  <TablerIcon icon={IconX} stroke={1.5} size={30} />
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => rotateImage(90)}
                >
                  <TablerIcon icon={IconRotateClockwise2} stroke={1.5} size={30} />
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => rotateImage(-90)}
                >
                  <TablerIcon icon={IconRotate2} stroke={1.5} size={30} />
                </button>
              </div>
              <div style={{
                position: 'fixed',
                left: '50%',
                bottom: '15px',
                transform: 'translateX(-50%)',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                zIndex: 1000001,
              }}
              >
                <button
                  type="button"
                  className="btn btn-warning btn-lg mx-2"
                  onClick={onCrop}
                >
                  <TablerIcon icon={IconPhotoUp} stroke={1.5} size={30} />
                  {' '}
                  Załaduj
                </button>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {isSuccessUpload ? (
            <div className="dropzone completed">
              <p>{completedUploadMessage}</p>
            </div>
          ) : (isUploading ? (
            <div className="dropzone focused">
              <p>
                {showProgress && `${progress.toFixed(1)}% `}
                {uploadMessage}
              </p>
              <p className="close" onClick={handleAbort}>x</p>
              {showProgressBar && <div className="progress" style={{ width: `${progress}%` }} />}
            </div>
          ) : (
            <div
              {...getRootProps()}
              className={(isFocused || isDragAccept || isDragActive || isUploading) ? 'dropzone focused' : 'dropzone'}
            >
              {children({ getInputProps }) || (
              <>
                <p>Drag & Drop image (png, jpg, jpeg)</p>
                <p>
                  Max size
                  {formatBytes(maxSize)}
                </p>
                <input {...getInputProps()} />
              </>
              )}
            </div>
          )
          )}
        </>
      )}
    </>
  );
}
