import BasePage from './layouts/BasePage';

export default function RegisteredPage() {
  return (
    <BasePage>
      <section className="container">
        <div className="row my-5 py-5">
          <div className="col-md-5 m-auto p-5">
            <h1 className="text-center mb-5">Potwierdź adres email</h1>
            <p>
              Dziękujemy za założenie konta! Aby zweryfikować swój adres e-mail, kliknij w link, który przesłaliśmy na podany adres e-mail.
            </p>
          </div>
        </div>
      </section>
    </BasePage>
  );
}
