import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react';
import App from './App';
import { StoreProvider } from './context/StoreContext';
import FullscreenLoading from './components/FullscreenLoading';

const client = new QueryClient();

Sentry.init({
  dsn: 'https://673a332fae4ecc58a6ee3faf28281828@o4506842269810688.ingest.us.sentry.io/4508655264006144',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/rozwiazto\.pl\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <React.StrictMode>
    <Suspense fallback={<FullscreenLoading />}>

      <QueryClientProvider client={client}>
        <StoreProvider>
          <App />
        </StoreProvider>
      </QueryClientProvider>

    </Suspense>
  </React.StrictMode>,
);
