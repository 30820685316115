import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import {
  Link, useNavigate, useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';

import * as Yup from 'yup';

import { FBLoginButton } from '@components/FBLoginButton';
import SuperButton from '../components/SuperButton';
import { useAuth } from '../hooks/auth';
import { toastFormikErrors } from '../utils';
import BasePage from './layouts/BasePage';
import ForgotPasswordModel from '../components/ForgotPasswordModel';

export default function LoginPage({ googleAuthInitialized }) {
  const { Authenticate, VerificationAuth } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('to');

  if (searchParams.get('verified')) {
    const user = JSON.parse(decodeURIComponent(searchParams.get('user')));
    VerificationAuth(searchParams.get('token'), user);

    searchParams.delete('verified');
    searchParams.delete('token');
    searchParams.delete('user');

    toast.success('Konto zostało aktywowane. Zostałeś zalogowany.');
    return navigate('/');
  }

  if (searchParams.get('verified_error')) {
    toast.error('Wystąpił błąd.');
    searchParams.delete('verified_error');
  }

  // To show forget password form
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (googleAuthInitialized && window.google?.accounts?.id) {
      /* global google */
      google.accounts.id.renderButton(document.getElementById('signInDiv'), {
        type: 'standard',
        shape: 'pill',
        text: 'signin_with',
        theme: 'outline',
        size: 'large',
        width: '320',
      });
    }
  }, [googleAuthInitialized]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Błędny adres email.').required('Adres email wymagany.'),
      password: Yup.string().required('Hasło wymagane.'),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      Authenticate(values.email, values.password).then((data) => {
        if (!data.errors) {
          toast.success(data.message);
          if (redirectTo) return navigate(redirectTo);
        } else toast.error(data.message);

        formik.setSubmitting(false);
      });
    },
  });

  const onClickSignIn = useCallback(() => {
    toastFormikErrors(formik.errors);
  }, []);

  return (
    <BasePage>
      <section className="container">
        <div className="row my-lg-4 my-0 py-lg-4 py-3">
          <div className="col-md-5 m-auto p-5">
            <h1 className="text-center mb-5">Logowanie</h1>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email:</label>
                <input type="text" {...formik.getFieldProps('email')} id="email" className="form-control" />
              </div>
              <div className="mb-3">
                <div>
                  <label htmlFor="password" className="form-label">Hasło:</label>
                  <Link onClick={() => setShow((prev) => !prev)} className="float-end">
                    Zapomniałeś
                    hasła?
                  </Link>
                </div>
                <input
                  type="password"
                  {...formik.getFieldProps('password')}
                  id="password"
                  className="form-control"
                />
              </div>
              <SuperButton
                isLoading={formik.isSubmitting}
                onClick={onClickSignIn}
                className="btn btn-primary btn-block my-4"
                type="submit"
                disabled={!formik.isValid}
              >
                Zaloguj
              </SuperButton>
            </form>
            <div className="mb-3 d-flex justify-content-center">
              <div id="signInDiv" />
            </div>
            <div className="mb-3 d-flex justify-content-center">
              <FBLoginButton />
            </div>
            <span>
              Nie masz jeszcze konta?
              {' '}
              <Link to="/register">Zarejestruj się</Link>
            </span>
          </div>
        </div>
      </section>

      <ForgotPasswordModel show={show} onHide={() => setShow((prev) => !prev)} setShow={setShow} />
    </BasePage>
  );
}
